.my-profile-tooltip-container .avatar {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 40px; }

.tooltip-container a, .tooltip-container button {
  font-size: 14px !important;
  color: #eb1478;
  text-decoration: none;
  transition: all 0.2s ease;
  margin-top: 20px;
  padding-bottom: 10px;
  margin: 20px 10px 0 10px; }
  .tooltip-container a:hover, .tooltip-container button:hover {
    color: #666;
    padding-bottom: 1px;
    border-bottom: 1px solid #666; }

.tooltip-container a {
  margin-bottom: 40px; }

.tooltip-container button {
  border-radius: 0; }
