.comment-item {
  margin-bottom: 1em;
  font-size: 0.9em; }
  .comment-item .comment-item-creator {
    display: inline-block; }
  .comment-item .comment-body {
    padding: 0 3em; }
    .comment-item .comment-body a {
      color: #eb1478;
      transition: .2s ease all; }
      .comment-item .comment-body a:hover {
        border-bottom: 1px solid #eb1478; }
  .comment-item .avatar {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    border-radius: 40px; }
