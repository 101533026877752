.complete-filter {
  display: inline-block; }

.complete-tooltip-container span {
  display: block; }

.complete-tooltip-container a {
  font-size: 14px !important;
  color: #eb1478;
  text-decoration: none;
  transition: all 0.2s ease;
  padding: 5px; }
  .complete-tooltip-container a:hover {
    color: #666;
    padding-bottom: 1px;
    border-bottom: 1px solid #666; }
  .complete-tooltip-container a.active {
    color: #eb1478;
    padding-bottom: 10px;
    font-weight: bold; }

.complete-tooltip-container button {
  border-radius: 0; }
