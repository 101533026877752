.set-user-info {
  margin-bottom: 1em;
  font-size: 0.9em; }
  .set-user-info .modal-header {
    display: block; }
    .set-user-info .modal-header .avatar {
      max-width: 100px;
      max-height: 100px; }
  .set-user-info .submit-wrapper {
    margin-top: 2em;
    text-align: center; }
    .set-user-info .submit-wrapper .button {
      font-size: 1em;
      background-color: #eb1478;
      color: white;
      padding: 0 6em;
      height: 3em; }
      .set-user-info .submit-wrapper .button:hover {
        font-size: 1.1em; }
  .set-user-info .user-info-input {
    border: 1px solid #d5dce0; }
    .set-user-info .user-info-input:hover {
      padding: 5px;
      border-color: rgba(235, 20, 120, 0.66); }
    .set-user-info .user-info-input:focus {
      padding: 5px;
      border-color: #eb1478; }
