.take-ownership-modal {
  margin-bottom: 1em;
  font-size: 0.9em; }
  .take-ownership-modal .modal-header {
    display: block; }
    .take-ownership-modal .modal-header .avatar {
      max-width: 100px;
      max-height: 100px; }
  .take-ownership-modal button {
    font-size: 1em;
    color: white;
    padding: 0 6em;
    height: 3em;
    color: #1A181B;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0.5em 0; }
    .take-ownership-modal button:hover {
      font-size: 1.1em;
      padding: 0.1em 5.4em; }
  .take-ownership-modal .yes-btn {
    color: #1A181B;
    background-color: #32936F;
    margin-left: 1em; }
