.add-comment {
  background: #f6f8f9;
  padding: 14px 5px; }
  .add-comment .hidden {
    display: none; }
  .add-comment .textarea-body {
    width: calc(100% - 80px);
    transition: border-color 100ms;
    font-size: 18px;
    font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif;
    line-height: 1.2em;
    padding: 5px;
    text-rendering: optimizeSpeed;
    vertical-align: top;
    border-color: white;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid #d5dce0;
    resize: none;
    transition: all 1s ease; }
    .add-comment .textarea-body:hover {
      border-color: #eb1478; }
  .add-comment .avatar {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .add-comment .button-add-comment {
    margin-top: 1.5em;
    transition: all 1s ease; }
