#google_translate_element {
  float: left;
  display: inline-block; }
  #google_translate_element .goog-te-gadget-simple {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0 0 0 10px;
    line-height: 1.2em; }
  #google_translate_element .goog-te-gadget-icon {
    display: none; }

.google-translate-container {
  float: left; }
  .google-translate-container .close-button {
    padding: 0;
    min-width: 0;
    margin: 0 8px 0 8px; }
    .google-translate-container .close-button .close-icon {
      font-size: 16px; }
