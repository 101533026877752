.task-view-header {
  padding: 8px 10px;
  border-bottom: 1px solid #e0e6e8; }
  .task-view-header .close-bottom {
    padding-bottom: 15px; }
  .task-view-header .close-icon {
    color: #ccd1d6; }
    .task-view-header .close-icon:hover {
      color: #eb1478; }
  .task-view-header .action-button {
    margin: 5px 20px; }
    .task-view-header .action-button .delete {
      vertical-align: sub;
      font-size: 20px;
      color: black; }
  .task-view-header .avatar-container {
    display: inline-block; }
  .task-view-header .avatar {
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .task-view-header .header-icon {
    color: #eb1478; }
