.reports-page {
  padding: 1em 2em 0 2em; }
  .reports-page a {
    color: #eb1478; }
    .reports-page a:hover {
      text-decoration: underline; }
  .reports-page .report-table {
    width: 90%;
    margin: auto;
    margin-top: 20px; }
  .reports-page .report-table th {
    border: 1px solid #bbb;
    text-align: left;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: 400; }
  .reports-page .report-table thead tr th {
    font-weight: 600;
    background: gray;
    text-transform: capitalize; }
  .reports-page .report-table tr:nth-child(odd) th {
    background: #ddd; }
