*,
*:before,
*:after {
  box-sizing: inherit; }

html {
  box-sizing: border-box; }

blockquote,
body,
dd,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
pre,
ul {
  margin: 0; }

fieldset,
iframe {
  border: 0; }

fieldset,
legend,
ol,
td,
th,
ul {
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

html {
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  cursor: default;
  background-color: #f1f4f5; }

body {
  font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.33333;
  color: #151b26; }

a {
  background-color: transparent; }
  a:active, a:hover {
    outline: 0; }

button {
  overflow: visible;
  cursor: pointer; }
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }

hr {
  border: 0;
  border-top: 1px solid; }

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@media only screen and (min-width: 768px) {
  .g-row {
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    max-width: 100%;
    padding: 0 20px; }
    .g-row:before, .g-row:after {
      content: '';
      display: table; }
    .g-row:after {
      clear: both; }
    .g-row .g-row {
      margin: 0 -15px;
      width: auto; }
  .g-col {
    width: 100%; }
  .g-col-40 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 40%; }
  .g-col-30 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 30%; }
  .g-col-50 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 50%; }
  .g-col-60 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 60%; }
  .g-col-70 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 70%; }
  .g-col-80 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 80%; }
  .g-col-90 {
    float: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 90%; } }

@media (max-width: 767px) {
  .g-row {
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    max-width: 100%;
    padding: 0 5px; }
    .g-row:before, .g-row:after {
      content: '';
      display: table; }
    .g-row:after {
      clear: both; }
    .g-row .g-row {
      margin: 0 -15px;
      width: auto; } }

html {
  overflow-y: scroll; }

a {
  color: inherit;
  text-decoration: none; }

.hide {
  display: none !important; }

::selection {
  background: rgba(200, 200, 255, 0.1); }

button:focus {
  outline: 0; }

div:focus {
  outline: 0; }

.label-default {
  display: inline;
  padding: .2em .6em .2em;
  font-size: 67%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  border-color: lightgreen; }

.label-default li {
  border-color: red; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c0c1c1; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #c0c1c1; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #c0c1c1; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #c0c1c1; }

.grow {
  transition: all 0.3s ease; }

.grow:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

input {
  font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif;
  font-size: 18px;
  border: 1px solid #e5dce0;
  height: 2em; }

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type='checkbox'] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  appearance: checkbox !important; }

.float-dir-right {
  float: right; }

.float-dir-left {
  float: left; }

.goog-te-banner-frame.skiptranslate {
  display: none !important; }

body {
  top: 0px !important; }
