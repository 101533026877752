.auto-suggested-wrapper {
  display: flex;
  flex-direction: row-reverse; }
  .auto-suggested-wrapper .suggestionContainer {
    z-index: 5; }
  .auto-suggested-wrapper .react-tagsinput-tag {
    align-self: baseline;
    width: 100%; }
  .auto-suggested-wrapper .suggestions {
    width: 100%;
    position: relative;
    background-color: white; }
  .auto-suggested-wrapper .suggestions li {
    background: rgba(255, 255, 255, 0.66);
    display: block;
    width: 100%; }
  .auto-suggested-wrapper .suggestion {
    display: block;
    width: 100%;
    z-index: 6; }
  .auto-suggested-wrapper li.react-autosuggest__suggestion--highlighted {
    background: #eb1478;
    color: white; }
  .auto-suggested-wrapper .react-autosuggest__container {
    display: inline-block;
    width: 100%; }
