.me a {
  color: #eb1478; }

.me .projects {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.me .project {
  display: inline-flex;
  flex-flow: wrap;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: white;
  padding: 5px;
  margin: 10px 0; }
  .me .project:hover {
    background-color: #eeeeee; }
  .me .project span {
    margin: 0 10px; }
    .me .project span:first-child {
      margin: 0; }
