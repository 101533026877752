@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.sign-in {
  margin-top: 10px; }
  .sign-in h3 {
    font-weight: normal; }
  .sign-in .sign-in-buttons {
    max-width: 300px;
    margin: 0 auto; }
  .sign-in .done {
    font-size: 30px;
    color: #eb1478; }

.sign-in__heading {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 300;
  text-align: center; }

.sign-in__content {
  margin-top: 40px; }

.sign-in__button {
  margin-bottom: 10px;
  border: 1px solid #848f9b;
  width: 100%;
  height: 48px;
  font-family: inherit;
  font-size: 1.125rem;
  line-height: 48px;
  color: #999; }
  .sign-in__button:hover {
    color: white;
    background: #eb1478; }
