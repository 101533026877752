.button--icon {
  border-radius: 40px;
  padding: 8px;
  width: 40px;
  height: 40px; }

.button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transform: translate(0, 0);
  background: white;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 1;
  min-width: 50px;
  text-align: center;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: background, border, box-shadow, color;
  transition-property: background, border, box-shadow, color;
  transition: all 0.2s ease; }
  .button[disabled] {
    cursor: default; }
  .button:hover {
    outline: none;
    text-decoration: none; }

.button-no-border {
  border: none; }

.button-small, .button-grey {
  padding: 0 9px;
  border-color: #eb1478;
  fill: #eb1478;
  height: 30px;
  line-height: 30px; }
  .button-small:hover, .button-grey:hover, .button-small:focus, .button-grey:focus {
    border-color: none;
    box-shadow: 0 0 0 2px #eb1478;
    color: #eb1478; }

.button-grey {
  border-color: #c0c0c0; }
  .button-grey:hover {
    border-color: #eb1478; }
