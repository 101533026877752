.header {
  padding: 0;
  height: 50px;
  overflow: hidden;
  line-height: 44px; }
  .header a:hover {
    color: #eb1478; }
  .header h4 {
    display: inline-block; }
  .header .create-project-header {
    display: inline-block;
    margin: 0 0.5em; }
  .header button {
    font-size: 0.8em;
    padding: 0.3em;
    transition-duration: .2s;
    transition: all 0.2s ease;
    background: none;
    border: solid 1px #ccc;
    border-radius: 4px; }
    .header button:hover {
      color: #eb1478; }
  @media only screen and (max-width: 480px) {
    .header .toastify--top-center {
      left: 0;
      right: 0;
      padding: 0 20px; } }
  .header .toastify {
    z-index: 10000; }
  .header .lang-select {
    margin-left: 0.5em; }
  .header .lang-right {
    float: right; }
  .header .lang-left {
    float: left; }
  .header .start-project {
    float: right;
    margin: 0 0.5em; }

.header-title {
  margin: 0 0 0 0.5em;
  display: flex;
  align-items: center;
  float: right;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 40px;
  text-rendering: auto;
  transform: translate(0, 0); }
  .header-title:before {
    display: inline-block;
    border: 2px solid #eb1478;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    content: ' '; }
  .header-title a {
    color: black;
    transition: all .2s ease; }
    .header-title a:hover {
      color: #eb1478; }
    .header-title a.active {
      color: #eb1478;
      border-bottom: 4px solid #eb1478; }

.header-actions {
  float: left;
  padding: 8px 0 0 0;
  line-height: 24px; }
  .header-actions:after {
    clear: both;
    content: '';
    display: table; }
  .header-actions li {
    float: left;
    list-style: none; }
    .header-actions li:last-child {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid #333; }
    .header-actions li:first-child {
      border: none; }
  .header-actions .button {
    display: block;
    margin: 0;
    color: #999;
    font-size: 0.875rem;
    line-height: 24px; }
  .header-actions .link {
    display: block;
    fill: #98999a;
    transform: translate(0, 0); }
  .header-actions .link--github {
    padding-top: 1px;
    width: 22px;
    height: 24px; }
