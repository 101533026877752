.tags-suggestions {
  margin-top: 14px;
  margin-bottom: 10px; }
  .tags-suggestions button {
    border: none;
    margin-bottom: 0.4em;
    padding: 2px; }
  .tags-suggestions .label-default {
    color: #888; }
  .tags-suggestions li {
    margin: 0;
    font-size: 13px;
    line-height: 2em;
    font-weight: 400;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #cde69c; }
    .tags-suggestions li:hover {
      background-color: #cde69c;
      color: #638421;
      border: 1px solid #a5d24a; }
