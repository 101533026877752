.task-filters {
  padding: 0 60px;
  font-size: 1rem;
  line-height: 24px;
  display: inline-block;
  width: 100%; }
  .task-filters:after {
    clear: both;
    content: '';
    display: table; }
  .task-filters ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap; }
  .task-filters .react-tagsinput-custom {
    position: absolute;
    z-index: 2;
    border-radius: 3px;
    overflow: hidden;
    margin-top: -7px;
    padding: 5px 1px 1px 1px;
    width: auto;
    min-width: 186px;
    display: inline-block;
    font-family: '"aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif' !important;
    font-size: 16px !important;
    border: 1px solid #d5dce0;
    padding: 4px 0 0 0; }
    .task-filters .react-tagsinput-custom input {
      font-size: 1.0em;
      font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif;
      width: 10em;
      background: none; }
    .task-filters .react-tagsinput-custom:hover {
      border: 1px solid #eb1478; }
  .task-filters .main-filters > li {
    float: right;
    height: 3em; }
    .task-filters .main-filters > li:not(:first-child) {
      margin-right: 7px; }
    .task-filters .main-filters > li:not(:first-child):before {
      padding-right: 7px;
      padding-left: 7px;
      content: ' '; }
    @media (min-width: 768px) {
      .task-filters .main-filters > li:not(:first-child):before {
        content: '|';
        font-weight: 300;
        padding-right: 14px;
        padding-left: 14px; }
      .task-filters .main-filters > li:last-child:before {
        content: ''; } }
    .task-filters .main-filters > li a {
      color: #999;
      text-decoration: none;
      transition: all 0.2s ease;
      padding-bottom: 14px; }
      .task-filters .main-filters > li a:hover {
        color: #666;
        padding-bottom: 10px;
        border-bottom: 4px solid #666; }
      .task-filters .main-filters > li a.active {
        color: #eb1478;
        padding-bottom: 10px;
        border-bottom: 4px solid #eb1478; }
  @media only screen and (min-width: 320px) {
    .task-filters {
      padding: 0em 1em 0.3em 1em; }
      .task-filters .task-filters li:last-child {
        width: 100%; } }
  @media (max-width: 480px) {
    .task-filters li:last-child {
      width: 100%; } }
