.task-item {
  display: flex;
  outline: none;
  border-bottom: 1px solid #e0e6e9;
  height: 45px;
  overflow: hidden;
  color: #151b28;
  font-weight: 300;
  border-top: 0px solid transparent;
  padding: 1px;
  cursor: pointer; }
  @media screen and (min-width: 33.75em) {
    .task-item {
      font-size: 1.125rem; } }
  .task-item:hover {
    border-top: 1px solid #e0e6e9;
    padding-top: 0; }
  .task-item .avatar {
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .task-item .warning {
    color: #eb1478;
    padding: 0 0 0 5px; }
  .task-item .new-task {
    background: #eb1478;
    color: white; }

.task-item.is-active {
  background-color: #eb1478;
  color: white; }
  .task-item.is-active .warning {
    color: white; }

.cell:first-child, .cell:last-child {
  display: flex;
  flex: 0 0 auto;
  align-items: center; }

.cell:first-child {
  padding-right: 10px;
  padding-left: 10px; }

.cell:nth-last-child(3) {
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  display: flex;
  align-items: center; }

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .label-cell {
    max-width: 110px; } }

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .label-cell {
    max-width: 40px; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .label-cell {
    max-width: 30px; } }

@media (max-width: 767px) {
  .label-cell {
    max-width: 100px; } }

.task-item-title {
  display: inline-block;
  position: relative;
  max-width: 100%;
  line-height: 40px;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .task-item-title:after {
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 2px solid #85bf6b;
    width: 0;
    height: 46%;
    content: ''; }

.is-done {
  text-decoration: line-through; }

.label-default {
  margin: 0 3px; }

.task-item-assignee {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
