.task-view-container {
  border-radius: 3px 3px 0 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background: white;
  line-height: 1.1em;
  margin-bottom: 2em;
  z-index: 10;
  font-size: 18px; }
  .task-view-container .instruction {
    font-size: 16px;
    margin-top: 0.5em;
    margin-bottom: 0.4em;
    margin-left: 1em;
    float: right; }
  .task-view-container .instruction-label {
    font-size: 16px;
    margin-top: 0.5em;
    margin-bottom: 0.4em; }
  .task-view-container .avatar {
    margin: 8px 5px;
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .task-view-container .avatar-creator {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.task-view-loader {
  min-height: 18.35em; }

@media screen and (max-width: 767px) {
  .task-view-loader {
    min-height: initial;
    line-height: 0; } }

.task-view-bottom-loader {
  min-height: 23.35em; }

.task-view {
  padding: 10px 25px;
  overflow-y: auto; }
  .task-view textarea {
    resize: none;
    font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif !important; }
  .task-view input:disabled, .task-view select:disabled, .task-view textarea:disabled {
    background: none !important;
    color: #545454 !important;
    border: none !important;
    font-size: 18px;
    padding: 5px 0;
    margin: 0;
    font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif !important; }
  .task-view input[type='checkbox'] {
    opacity: 1;
    position: relative; }
  .task-view .Select {
    vertical-align: middle; }
  .task-view .is-disabled .Select-control {
    border: none !important; }
    .task-view .is-disabled .Select-control:hover {
      border: none !important; }
  .task-view .Select-placeholder {
    color: red; }
  .task-view .Select-value {
    background-color: white;
    padding-right: 0 !important; }
  .task-view .Select-control {
    width: 200px !important;
    font-size: 18px; }
    .task-view .Select-control:hover {
      border: 1px solid #ccc !important; }
  .task-view .Select-menu-outer {
    width: 200px !important; }
  .task-view .button-save {
    margin: 10px 6px; }
  .task-view .label {
    vertical-align: top;
    margin-top: .7em; }
  .task-view .is-critical {
    margin-bottom: 10px; }

.changing-input {
  width: 100%;
  transition: border-color 100ms;
  font-size: 18px;
  height: 35px;
  padding: 5px;
  border: none;
  text-rendering: optimizeSpeed;
  vertical-align: top;
  border-color: white;
  overflow: hidden; }

.editable {
  border: 1px solid #e5dce0; }
  .editable:hover {
    border: 1px solid #d5dce0; }

.react-tagsinput-changing {
  border: 1px solid #ccc;
  overflow: hidden;
  padding: 5px 1px 1px 1px;
  width: calc(100% - 60px);
  display: inline-block; }
  .react-tagsinput-changing input {
    font-size: 18px;
    font-family: "aktiv-grotesk-std", Helvetica Neue, Arial, sans-serif;
    width: calc( 100% - 130px); }
  .react-tagsinput-changing ::placeholder {
    color: red; }

.form-input {
  margin: 0.4em 0; }
