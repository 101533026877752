.add-task-button {
  margin: 0.43em;
  display: inline-block; }

.task-page-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse; }

.task-page-child {
  margin: auto; }

.task-list-wrapper {
  z-index: 2;
  max-width: 50%;
  min-width: 50%;
  flex: 5500 1 605px;
  -webkit-box-flex: 5500;
  -ms-flex: 5500 1 605px;
  padding-left: 10px;
  padding-right: 10px; }

.task-view-wrapper {
  justify-content: flex-start;
  min-width: 50%;
  max-width: 800px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px; }

@media (max-width: 767px) {
  .task-list-wrapper {
    max-width: 100%;
    min-width: 0; }
  .task-page-wrapper {
    flex-wrap: wrap; }
  .task-view-wrapper {
    min-width: 50%; } }

@media (max-width: 1120px) {
  .task-view-wrapper {
    min-width: 50%; } }

@media (max-width: 795px) {
  .task-view-wrapper {
    width: 100%;
    min-width: 50%; } }
