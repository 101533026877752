.admin-dashboard {
  margin: 1.5em; }
  .admin-dashboard .hide-me {
    transition: opacity 300ms 0ms;
    opacity: 0.0; }
  .admin-dashboard .button {
    color: red;
    height: 2em;
    margin: 1em 0; }
  .admin-dashboard .button[disabled] {
    color: #ccc; }
