.set-project {
  padding: 0 1em 1em 1em;
  margin-top: 1em;
  background: white;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  max-width: 450px; }
  .set-project a {
    color: #eb1478; }
  .set-project input {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease; }
    .set-project input:hover {
      border-color: #eb1478; }
  .set-project .form-input {
    margin-bottom: 1em; }
  .set-project input[type=text] {
    min-width: 400px; }
  .set-project input[type=checkbox] {
    opacity: 1;
    position: relative;
    display: inline-block; }
  .set-project .extra-fields .react-tagsinput-tag {
    color: white;
    background-color: #eb1478;
    border: none; }
  .set-project .extra-fields .react-tagsinput-remove {
    color: white; }
