.projects-page {
  padding: 1em 2em 0 2em; }
  .projects-page a {
    color: #eb1478; }
    .projects-page a:hover {
      text-decoration: underline; }
  .projects-page .report-table {
    width: 90%;
    margin: auto;
    margin-top: 20px; }
  .projects-page .report-table th {
    border: 1px solid #bbb;
    text-align: left;
    padding: 2px 4px;
    font-size: 1em;
    font-weight: 400; }
  .projects-page .dir-right th {
    text-align: left; }
  .projects-page .dir-left th {
    text-align: right; }
  .projects-page .report-table thead tr {
    font-weight: 600;
    background: gray;
    font-size: 1em;
    text-transform: capitalize; }
  .projects-page .report-table tr:nth-child(odd) th {
    background: #ddd; }
