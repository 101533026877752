.task-list-container {
  border-radius: 3px 3px 0 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
  overflow: hidden; }
  .task-list-container .task-list-header {
    padding: 8px 10px;
    border-bottom: 1px solid #e0e6e8;
    background: white; }
    .task-list-container .task-list-header .close-bottom {
      padding-bottom: 15px; }
    .task-list-container .task-list-header .close-icon {
      color: #ccd1d6; }
      .task-list-container .task-list-header .close-icon:hover {
        color: #eb1478; }
    .task-list-container .task-list-header .action-button {
      margin: 0 20px; }
    .task-list-container .task-list-header .avatar-container {
      display: inline-block; }
    .task-list-container .task-list-header .avatar {
      margin: 0 10px;
      width: 40px;
      height: 40px;
      border-radius: 40px; }

.task-list {
  max-height: 27.5em;
  overflow-y: auto;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background: white; }
  .task-list .no-tasks-placeholder {
    padding: 10px; }
    .task-list .no-tasks-placeholder a {
      color: #eb1478; }
      .task-list .no-tasks-placeholder a:hover {
        border-bottom: 1px solid #eb1478; }

.task-list-loader {
  min-height: 29.23em; }

@media (max-width: 767px) {
  .task-list-loader {
    min-height: 25.23em; } }
